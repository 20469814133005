<script lang="ts">
  export let color: string = 'currentColor';
  let clazz: string = '';
  export { clazz as class };
  export let style: string = '';
</script>

<!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 77.83 24.5"
  style="fill: {color}; {style}"
  xml:space="preserve"
  class={clazz}
>
  <title>uTheory</title>
  <g id="ry">
    <g id="y">
      <path
        d="M67,21.7c0.15,0.07,0.35,0.14,0.6,0.2c0.24,0.06,0.49,0.09,0.72,0.09c0.77,0,1.37-0.17,1.81-0.51
			c0.43-0.34,0.83-0.9,1.18-1.67c-0.88-1.68-1.7-3.46-2.47-5.34s-1.4-3.79-1.9-5.72h2.1c0.15,0.63,0.34,1.31,0.56,2.04
			c0.22,0.73,0.46,1.48,0.72,2.25c0.27,0.77,0.55,1.54,0.86,2.31c0.31,0.77,0.63,1.51,0.97,2.23c0.53-1.47,0.99-2.93,1.39-4.37
			c0.39-1.44,0.76-2.93,1.11-4.45h2.02c-0.5,2.06-1.06,4.04-1.68,5.93s-1.28,3.67-2,5.32c-0.28,0.63-0.57,1.17-0.87,1.63
			c-0.3,0.46-0.63,0.83-1,1.12c-0.36,0.29-0.77,0.51-1.23,0.65c-0.46,0.14-0.97,0.21-1.54,0.21c-0.15,0-0.31-0.01-0.48-0.03
			c-0.17-0.02-0.33-0.05-0.49-0.08c-0.16-0.04-0.31-0.07-0.44-0.12c-0.13-0.04-0.23-0.08-0.28-0.11L67,21.7z"
      />
    </g>
    <path
      id="r"
      d="M64.84,7.91c0.17,0,0.36,0.01,0.58,0.03c0.22,0.02,0.43,0.05,0.64,0.08c0.21,0.04,0.4,0.07,0.58,0.1
		c0.18,0.04,2.28,0.58,2.36,0.61l-1.7,1.41c-0.15-0.06-1.02-0.34-1.38-0.42c-0.36-0.08-0.82-0.12-1.38-0.12
		c-0.36,0-0.72,0.04-1.08,0.12c-0.36,0.08-0.59,0.13-0.7,0.16v9.49H60.8V8.6c0.46-0.17,1.04-0.33,1.72-0.47
		C63.21,7.98,63.98,7.91,64.84,7.91z"
    />
  </g>
  <path
    id="o"
    d="M57.85,13.64c0,0.87-0.13,1.65-0.38,2.35c-0.25,0.7-0.61,1.3-1.06,1.81c-0.46,0.5-1,0.89-1.63,1.17
	c-0.63,0.27-1.32,0.41-2.06,0.41s-1.43-0.14-2.06-0.41c-0.63-0.27-1.17-0.66-1.63-1.17c-0.46-0.5-0.81-1.11-1.06-1.81
	c-0.25-0.7-0.38-1.48-0.38-2.35c0-0.85,0.13-1.63,0.38-2.34c0.25-0.71,0.6-1.31,1.06-1.82c0.46-0.5,1-0.89,1.63-1.17
	c0.63-0.27,1.32-0.41,2.06-0.41s1.43,0.14,2.06,0.41s1.17,0.66,1.63,1.17c0.46,0.5,0.81,1.11,1.06,1.82S57.85,12.79,57.85,13.64z
	 M55.81,13.64c0-1.23-0.28-2.21-0.83-2.93c-0.55-0.72-1.31-1.08-2.26-1.08s-1.7,0.36-2.26,1.08c-0.55,0.72-0.83,1.7-0.83,2.93
	s0.28,2.21,0.83,2.93c0.55,0.72,1.31,1.08,2.26,1.08s1.7-0.36,2.26-1.08C55.53,15.85,55.81,14.87,55.81,13.64z"
  />
  <path
    id="e"
    d="M35.48,13.66c0-0.97,0.14-1.81,0.42-2.53c0.28-0.72,0.65-1.32,1.11-1.8c0.46-0.48,0.99-0.83,1.6-1.07
	c0.6-0.24,1.22-0.36,1.85-0.36c1.47,0,2.6,0.46,3.38,1.38c0.78,0.92,1.18,2.31,1.18,4.19c0,0.08,0,0.19,0,0.33
	c0,0.13-0.01,0.26-0.02,0.37h-7.48c0.08,1.13,0.41,2,0.99,2.58c0.57,0.59,1.47,0.88,2.69,0.88c0.69,0,1.26-0.06,1.73-0.18
	c0.47-0.12,0.82-0.23,1.06-0.35l0.27,1.64c-0.24,0.13-0.65,0.26-1.25,0.4c-0.6,0.14-1.27,0.21-2.03,0.21
	c-0.95,0-1.77-0.14-2.47-0.43c-0.69-0.29-1.26-0.68-1.71-1.19c-0.45-0.5-0.78-1.1-1-1.8C35.59,15.25,35.48,14.49,35.48,13.66z
	 M42.98,12.59c0.01-0.88-0.21-1.61-0.66-2.17c-0.46-0.57-1.08-0.85-1.88-0.85c-0.45,0-0.84,0.09-1.19,0.26
	c-0.34,0.18-0.63,0.4-0.87,0.68c-0.24,0.28-0.42,0.6-0.56,0.97c-0.13,0.36-0.22,0.73-0.26,1.11H42.98z"
  />
  <g id="h">
    <path
      id="h-right-half"
      d="M23.97,14.77V2.96l1.94-0.34l0.01,5.73c0.36-0.14,0.75-0.25,1.17-0.33
		c0.41-0.08,0.82-0.12,1.23-0.12c0.87,0,1.59,0.12,2.16,0.37c0.57,0.25,1.03,0.59,1.38,1.03c0.34,0.44,0.58,0.97,0.72,1.59
		c0.14,0.62,0.21,1.29,0.21,2.04v6.44h-1.95v-6.02c0-0.67-0.05-1.25-0.14-1.72c-0.09-0.48-0.24-0.86-0.45-1.15s-0.49-0.51-0.84-0.64
		c-0.35-0.13-0.78-0.2-1.3-0.2c-0.21,0-0.43,0.01-0.65,0.04c-0.22,0.03-0.44,0.06-0.64,0.09c-0.2,0.04-0.39,0.07-0.55,0.12
		c-0.16,0.04-0.28,0.08-0.35,0.1v4.78H23.97z"
    />
    <path
      id="h-left-half"
      d="M23.97,8.42c0.45-0.11,1.04-0.23,1.77-0.36c0.74-0.13,1.59-0.19,2.55-0.19
		c0.84,0,1.55,0.12,2.12,0.37c0.57,0.24,1.04,0.59,1.39,1.04c0.35,0.45,0.6,0.98,0.76,1.59c0.15,0.61,0.23,1.28,0.23,2.03v6.46
		h-1.95v-6.04c0-1.33-0.21-2.28-0.63-2.86c-0.42-0.57-1.13-0.86-2.12-0.86c-0.21,0-0.43,0.01-0.65,0.02
		C27.21,9.64,27,9.65,26.8,9.67c-0.2,0.02-0.37,0.04-0.54,0.06c-0.16,0.02-0.28,0.05-0.35,0.07v9.56h-1.95V8.42z"
    />
  </g>
  <g id="T">
    <path id="t-vertical" d="M17.6,2.46v16.91h-2.04V2.46" />

    <rect
      id="t-horizontal"
      x="8.38"
      y="0.92"
      transform="matrix(-1 -8.243685e-11 8.243685e-11 -1 34.2827 3.8942)"
      width="17.53"
      height="2.05"
    />
  </g>
  <path
    id="u"
    d="M10.33,18.81c-0.45,0.11-1.04,0.23-1.77,0.36C7.82,19.3,6.97,19.36,6,19.36c-0.84,0-1.55-0.12-2.12-0.37
	c-0.57-0.24-1.04-0.59-1.39-1.04c-0.35-0.45-0.6-0.98-0.76-1.59c-0.15-0.61-0.23-1.28-0.23-2.03v-8.2h1.95v7.78
	c0,1.33,0.21,2.28,0.63,2.86c0.42,0.57,1.13,0.86,2.12,0.86c0.21,0,0.43-0.01,0.65-0.02c0.22-0.01,0.43-0.03,0.63-0.05
	c0.2-0.02,0.37-0.04,0.54-0.06c0.16-0.02,0.28-0.05,0.35-0.07V7.88h1.95V18.81z"
  />
</svg>
